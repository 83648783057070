import TutorialIndex from "../../../content/tutorial/using-dotter/index.fr";
import React from "react";
import Layout from "../../../components/Layout";

export default function Tutorial() {
  return (
    <Layout title="Utiliser Dotter.science au quotidien">
      <TutorialIndex lang="fr" currenttuto="usingdotter" />
    </Layout>
  );
}
